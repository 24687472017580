import { useI18n } from "vue-i18n"

interface MetaProps {
    key: string;
    link?: any[],
		localeLinks?: any[],
    script?: any[],
    follow?: boolean,
		shareMeta?: any[],
    htmlAttrs?: any,
    descriptionReplace?: any
}

export const stripHtml = (html?: string):string => html?.replace(/<[^>]*>?/gm, '') ?? '';

// NOTE set i18nRef if updated localy (like inside blog)
export const buildMeta = (props: MetaProps, i18nRef?:any, i18nHeadRef?:any ) => {
   
    const i18n = i18nRef ?? useI18n()
		const router = useRouter()
		const config = useRuntimeConfig()
		const i18nHead = i18nHeadRef ?? useLocaleHead({
			addSeoAttributes: true
		}) 

		const title = i18n.t(`${props.key}.meta.title`)
		const description = props.descriptionReplace ? i18n.t(`${props.key}.meta.description`, props.descriptionReplace) : i18n.t(`${props.key}.meta.description`) 

		if(props.follow && !props.shareMeta){
			props.shareMeta = [

				{ name: 'twitter:site', hid: 'twitter:site:creator', content: '@SmoxyApp' },
				{ name: 'twitter:text:title', hid: 'twitter:text:title', content: title },
				{ name: 'twitter:card', hid: 'twitter:card', content: 'summary' },
				{ name: 'twitter:description', hid: 'twitter:description', content: description },

				{ name: 'og:title', hid: 'og:title', content: title },
				{ name: 'og:description', hid: 'og:description', content: description },
				{ name: 'og:url', hid: 'og:url', content: config.public.hostname+router.currentRoute.value.fullPath },
				{ name: 'og:type', hid: 'og:type', content: 'website' },
				{ name: 'og:locale', hid: 'og:locale', content: i18nHead.value.htmlAttrs!.lang },	
			]
		}

    return {
        title: i18n.t(`${props.key}.meta.title`),
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs!.lang,//i18n.locale,
            ...(props.htmlAttrs ?? {})
        },
        
        meta: [
            { name: "description", hid: 'description', content: description },
            { name: "keywords", content: i18n.t(`${props.key}.meta.keywords`) },
            { name: "robots", hid: 'robots', content: props.follow ? "index,follow" : "noindex,nofollow" },
            // todo add share  infos.... only if follow.... add overwrite options...

						...(props.shareMeta ?? []),
						...(i18nHead.value.meta ?? []),
        ],
        script: [
            ...(props.script ?? []),
        ],
        link: [
            ...(props.link ?? []),
						...(props.localeLinks ?? i18nHead.value.link ?? []),
        ]
    }
} 